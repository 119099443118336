import useSelector from "hooks/redux/use-selector";
import useDispatch from "hooks/redux/use-dispatch";

import { coinsThunks } from "store/coins";

import { useURLContext } from "operations/router";

import { CoinsURL, CoinsURLParams } from "./types";

export const useCoinsURLParams = useURLContext.withTypes<
  CoinsURL,
  CoinsURLParams
>();

export const useConnect = values => {
  const isCurrenciesLoaded = useSelector(state => state.currencies.isLoaded);
  const coinsError = useSelector(state => state.coins.coinsError);
  const isLoading = useSelector(state => state.coins.isLoading);
  const coinIds = useSelector(state => state.coins.ids);
  const coinEntities = useSelector(state => state.coins.entities);
  const isNextCoins = useSelector(state => state.coins.isNextCoins);
  const loadMoreError = useSelector(state => state.coins.loadMoreError);

  const dispatch = useDispatch();

  return {
    coinIds,
    coinEntities,
    isNextCoins,
    loadMoreError,
    coinsError,
    isLoading,
    isCurrenciesLoaded,
    loadCoins: values => dispatch(coinsThunks.loadCoins(values)),
    loadMoreCoins: () => {
      dispatch(
        coinsThunks.loadCoins({
          ...values,
          isLoadMore: true,
        }),
      );
    },
  };
};
