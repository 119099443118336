import { FC, useEffect, useMemo, useRef } from "react";
import { isbot } from "isbot";
import { ClientLoaderFunction, defer, redirect } from "@remix-run/react";

import store from "store";

import { ROOT_META } from "constants/meta";

import { LoadCoinsArgs } from "store/coins/thunks";
import { coinsServerThunks } from "store/coins";

import useWindowSizes from "hooks/use-window-sizes";

import { urlSelector } from "selectors/url";
import { getSettledValue } from "selectors/redux";

import { createMeta } from "operations/meta";
import { connectURLParams } from "operations/router";
import { serverLoader } from "operations/moduleSync";

import { Sizes } from "basics/button";

import List from "components/list";
import InfiniteScroll from "components/infinite-scroll";

import globalClasses from "styles/classes.module.scss";

import Header from "./components/header";
import CoinRow from "./components/coin-row";
import { useCoinsURLParams, useConnect } from "./duck/hooks";
import { validator, BUY_BUTTON_ONLY_SCREEN } from "./duck/constants";
import { getListCellSchema } from "./duck/selectors";
import { CoinsURLParams, CoinsURL } from "./duck/types";

const Coins: FC = () => {
  const loadAbortRef = useRef<VoidFunction>(null);
  const { params, search, setSearchParams } = useCoinsURLParams();
  const loadFilter = { ...search, category: params.category };
  const {
    coinEntities,
    coinIds,
    coinsError,
    isLoading,
    isNextCoins,
    loadMoreError,
    isCurrenciesLoaded,
    loadCoins,
    loadMoreCoins,
  } = useConnect(loadFilter);
  const windowSizes = useWindowSizes();

  const { isMobileWidth, isTabletSmallWidth, isMobileSmallWidth } = windowSizes;

  const abortableLoadCoins = (values: LoadCoinsArgs) => {
    loadAbortRef.current?.();

    const loadPromise = loadCoins(values);

    loadAbortRef.current = () => loadPromise.abort();
  };

  useEffect(() => {
    abortableLoadCoins(loadFilter);

    return loadAbortRef.current;
  }, [search, params.category]);

  useEffect(() => {
    if (isMobileWidth && search.sort.includes("percent_24")) {
      setSearchParams({ sort: "rank" });
    }
  }, [isMobileWidth]);

  const isDescSort = search.sort.startsWith("-");

  const columnSchema = useMemo(
    () => getListCellSchema(windowSizes),
    [windowSizes],
  );

  const filteredCoins = useMemo(() => {
    let ids = coinIds;
    if (params.category === "watchlist") {
      ids = coinIds.filter(id => coinEntities[id].isFavorite);
    }

    return ids.map(id => coinEntities[id]);
  }, [coinIds, params.category, coinEntities]);

  const coinIconSize = isMobileSmallWidth ? "xs" : "sm";
  let buttonSize: Sizes = "lg";
  let comboSize: Sizes = "md";
  if (isMobileWidth || isTabletSmallWidth) {
    buttonSize = "md";
  }
  if (isMobileSmallWidth) {
    comboSize = "sm";
    buttonSize = "sm";
  }

  return (
    <main className={globalClasses.main}>
      <div className={globalClasses.content}>
        <List
          data={filteredCoins}
          isLoading={isLoading}
          error={coinsError}
          sortDirection={isDescSort ? "desc" : "asc"}
          activeSort={search.sort.slice(Number(isDescSort))}
          columnSchema={columnSchema}
          onSort={(key, direction) => {
            setSearchParams({
              sort: `${direction === "asc" ? "" : "-"}${key}` as CoinsSort,
            });
          }}
        >
          <Header />
          <List.Body<Coin>
            onReload={() => abortableLoadCoins(loadFilter)}
            rowHeight={windowSizes.isMobileSmallWidth ? 64 : 80}
            empty={{
              title: "No coins found",
              subtitle: "The list is empty for specified search query",
              type: "search",
            }}
            endContent={
              <InfiniteScroll
                onLoadMore={loadMoreCoins}
                isLoading={isLoading}
                isNeedExecute={isNextCoins}
                isError={Boolean(loadMoreError)}
              />
            }
          >
            {({ style, item, key }) => (
              <CoinRow
                item={{
                  ...item,
                  sellAvailable:
                    windowSizes.size.width >= BUY_BUTTON_ONLY_SCREEN &&
                    item.sellAvailable,
                }}
                key={key}
                style={style}
                buttonSize={buttonSize}
                comboSize={comboSize}
                isCurrenciesLoaded={isCurrenciesLoaded}
                coinIconSize={coinIconSize}
              />
            )}
          </List.Body>
        </List>
      </div>
    </main>
  );
};

export const loader = serverLoader(async ({ params, request }) => {
  const categoriesResult = await coinsServerThunks.loadCategoriesServer();
  const categories = getSettledValue(categoriesResult);
  const category = categories.find(category => category.id === params.category);
  if (!category) {
    if (!isbot(request.headers.get("user-agent"))) {
      return redirect(urlSelector.coins({ category: "all" }));
    }

    throw new Response(null, { status: 404, statusText: "Not Found" });
  }

  return defer({
    [coinsServerThunks.loadCategoriesServer.typePrefix]: categoriesResult,
  });
});

export const clientLoader: ClientLoaderFunction = ({ serverLoader }) => {
  const categoriesResult = coinsServerThunks.loadCategoriesServer.selectResult(
    store.getState(),
  );
  if (categoriesResult) {
    return {
      [coinsServerThunks.loadCategoriesServer.typePrefix]: categoriesResult,
    };
  }

  return serverLoader();
};

export const meta = createMeta<typeof loader>(({ data, params }) => {
  const categories = coinsServerThunks.loadCategoriesServer.getValue(data);
  const categoryItem =
    categories.find(item => item.id === params.category) || categories[0];

  return {
    ...ROOT_META,
    isCanonical: true,
    title: `Coins / ${categoryItem.displayName}`,
  };
});

export default connectURLParams<CoinsURL, typeof validator, CoinsURLParams>(
  Coins,
  {
    prepareValues: (_raw, { values }) => {
      const { search = "", sort = "rank" } = values;

      return {
        search,
        sort,
      };
    },
    validator,
  },
);
